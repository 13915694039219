<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab == 'receiptTaskPanel'">
        <receipt-task-panel></receipt-task-panel>
      </div>
      <div v-else-if="currentTab == 'receiptRecordPanel'">
        <receipt-record-panel></receipt-record-panel>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    ReceiptTaskPanel: () => import("./ReceiptTaskPanel/index"),
    ReceiptRecordPanel: () => import("./ReceiptRecordPanel/index"),
  },
  data() {
    return {
      tabList: [
        {
          key: "receiptTaskPanel",
          tab: this.$t("收货任务"),
        },
        {
          key: "receiptRecordPanel",
          tab: this.$t("收货记录"),
        },
      ],
      currentTab: undefined,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
